import * as consenthandler from '@mod-publisher/js/analytics/consenthandler';
import * as whintegration from '@mod-system/js/wh/integration';

require('designfiles/wh.compat.base');
require('designfiles/wh.rich.content');
require('designfiles/wh.net.url');
require('designfiles/wh.media.embedvideo');
require('designfiles/wh.layout.masonry');
require('designfiles/wh.ui.pulldown2');
require('designfiles/wh.ui.checkbox');
require('designfiles/wh.ui.radiobutton');
require('designfiles/wh.net.jsonrpc');
require('designfiles/wh.util.template');
require('designfiles/wh.animations.slideshow');
require('designfiles/wh.layout.justifiedcontentgrid');
require('designfiles/wh.util.preloader');
require('designfiles/frameworks.mootools.more.keyboard');
require('designfiles/wh.google.maps');
require('designfiles/wh.ui.popup');
//require('designfiles/consilio.suggest')
require('designfiles/wh.social.sharepage');
require('../css/forms.css');
import '../css/medlon.css';
require('../css/xlightbox.css');
require('./renderer/lightbox.js');
require('../css/accounts-cursussen.css');

import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log
import * as dompack from 'dompack';

/*! LOAD: wh.compat.base, wh.rich.content, wh.net.url, wh.media.embedvideo, wh.layout.masonry, wh.ui.pulldown2, wh.ui.checkbox, wh.ui.radiobutton, wh.net.jsonrpc, wh.util.template
    LOAD: wh.animations.slideshow, wh.layout.justifiedcontentgrid, wh.util.preloader, frameworks.mootools.more.keyboard, wh.google.maps, wh.ui.popup, consilio.suggest, wh.social.sharepage
    USE: ../css/medlon.css, ../css/xlightbox.css, ./renderer/lightbox.js, ../css/accounts-cursussen.css
!*/

(function($) { //mootools/scope wrapper
"use strict";

var topsearchtimer;

function medlon_init()
{
  window.touch_enabled = "createTouch" in document;

  $wh.setNewWindowLinks({ 'externalhosts' : true, extensions: ['pdf'] });

  $wh.Pulldown2.replaceComponents("select.wh-pulldown");
  $wh.Checkbox.replaceComponents('input.wh-checkbox');
  $wh.Radiobutton.replaceComponents('input.wh-radiobutton');

  if($('em_searchform'))
    $('em_searchform').addEvent('submit',ReferenceSheet);

  if($(document.documentElement).hasClass('widgetpreview'))
    return; //if preview, ignore other part of initialization

//  var consilioservice = new $consilio.SuggestService({ catalog: "medlon" });
//  $consilio.Suggest.addToInput($("topsearch").getElement("input[name='words']"), consilioservice);

  $wh.PopupManager.setDefaultPopupOptions({ theme: "medlon", explicitclose: false, show: false, keepfromedge: 0, closebutton: false, scroll: 'popup_viewport' });

  $$('.widget .video, .embeddedobject.video > .video').addEvent('click', showVideoDialog /* startVideo */); //showVideoDialog

  //set date in rtd layout below heading1
  var datenode = $('pagedate');
  if(datenode && $('maincontent'))
  {
    var headingnode = $('maincontent').getElement('h1.heading1');
    if(headingnode)
      datenode.inject(headingnode,'after');
  }

  $$('#pictures, .embeddedobject.imageslist').each(function(container)
  { //set separate lightbox instances for each image group
    var lbimages = container.getElements('a[rel=lightbox]')
    if(lbimages.length)
      new XtLightbox(lbimages, { loop: true });
  });

  $$('.embeddedobject.imageslist').each(function(node)
  {
    var imgcount = node.getElements("img").length;
    if( imgcount > 1 )
    {
      var mygrid = new $wh.JustifiedImageGrid( node
                             , { width:      node.getSize().x
                               , row_height: 120
                               , gutter_x:    0
                               , gutter_y:    0
                               , fill_last_strip: true
                               }
                             );
      mygrid.importContent( node );
      node.store('grid',mygrid);
    }
  });

  if($('maincontent') && $('maincontent').hasClass('faq'))
    initFaqPage();

  $$('.togglesearch').addEvent('click', function(ev)
  {
    clearTimeout(topsearchtimer);
    ev.stop();
    if(!$('topsearch').hasClass('active'))
    {
      $('topsearch').addClass('active');
      $(document.body).addEvent('click',closeTopSearch);
      if(window.touch_enabled)
        $(document.body).addEvent('touchstart',closeTopSearch);
      topsearchtimer = setTimeout(function(){
        $('topsearch').addClass('aftershow');
      }, 300);
    }
    else
    {
      closeTopSearch(ev);
    }
  });

/*
  $('mobilemenu').addEvent('click:relay(a)',toggleSideNavItems);
  if(window.touch_enabled)
    $('mobilemenu').addEvent('touchstart:relay(a)',toggleSideNavItems);

  if($('sidenav'))
  {
    $('sidenav').addEvent('click:relay(a)',toggleSideNavItems);
    if(window.touch_enabled)
      $('sidenav').addEvent('touchstart:relay(a)',toggleSideNavItems);
  }
*/

  $('mobilemenu-open').addEvent('click',showMobileMenu);
  $('mobilemenu-close').addEvent('click', hideMobileMenu);

  $$('.tabscontainer').each(function(node)
  {
    new cTabsContainer(node);
  });


  if($('headerslides'))
  {
    if($('headerslides').getChildren('img').length > 1)
    {
      var slidesettings = { autoplay: true
                          , method: 'fade-in'
                          , slideduration: 1000
                          , delay: 5000
                          };
      new $wh.Slideshow($('headerslides'), slidesettings);
    }
  }
  else if($('gmap'))
    new headerMap();

  if($('pictures'))
    initPhotoAlbum();

  if($('summary'))
    initSummary();

  $(window).addEvent('resize',onResize);
  $(window).addEvent("load", onResize);//incase of slow font loading
  $(window).addEvent("scroll", onScroll);//for sticky menu

  onResize();
  onScroll();

  var pagemessage = $('page_messagebox');
  if(pagemessage)
  {
    var seenpage = Cookie.read("page_message");
    if( seenpage == $wh.config.obj.id )
    {
      pagemessage.destroy();
      return;
    }

    pagemessage.set("style","");
    var msgpopup = $wh.PopupManager.createFromElement($('page_messagebox'),{ theme: "medlon_message", valign_content:true, show: true, keepfromedge: 20, scroll: 'window' });

    msgpopup.addEvent("afterhide",function()
    {
      Cookie.write("page_message", $wh.config.obj.id, {duration:7});//keep cookie 7 days
    });
  }
}

function onScroll()
{
  var triggery = $('headerholder').getSize().y > 110 ? 95 : 40; //desktop or mobile view
  $(document.documentElement).toggleClass('fixedheader',window.getScroll().y >= triggery);
}

var summaryitems;
var summaryitemspp = 10;
var summarypagecount;
function initSummary()
{
  summaryitems = $('summary').getChildren('li');
  summarypagecount = Math.ceil(summaryitems.length / summaryitemspp);

  if(summarypagecount > 1)
  {
    $$('#summary_pagination_top,#summary_pagination_bottom').addEvent('mouseup:relay(a)', showSummaryItems);
    var paginationtoptemplate = $('summary_pagination_top').getElement("template");
    var paginationbottomtemplate = $('summary_pagination_bottom').getElement("template");

    var pagination = { pages : [] };
    for(var c = 0; c < summarypagecount; c++)
      pagination.pages.push({'title' : ''+(c+1), 'link' : '#page' + c, 'rowkey' : c});

    $wh.expandTemplate(paginationtoptemplate, pagination);
    $wh.expandTemplate(paginationbottomtemplate, pagination);

    $$('#summary_pagination_top,#summary_pagination_bottom').setStyle('display',null);
  }
  else
  {
    $$('#summary_pagination_top,#summary_pagination_bottom').destroy();
  }
  showSummaryItems();
}
function showSummaryItems(ev)
{
  var pagenr = 0;
  if(summarypagecount > 1)
  {
    if(ev)
    {
      ev.stop();
      if(ev.target.getParent().hasClass('active'))
        return;
    }

    var hashstr = ev ? ev.target.get('href') : location.hash;
    var startindex = hashstr.indexOf('page');
    if(startindex > -1)
    {
      var hashparts = hashstr.substring(startindex + 4).split('.');
      if(hashparts.length > 0)
        pagenr = hashparts[0].toInt();
    }

    if(isNaN(pagenr) || pagenr < 0)
      pagenr = 0;
    else if(pagenr > summarypagecount - 1)
      pagenr = summarypagecount - 1;

    $$('#maincontent .pagination li').removeClass('active');
    $$('#maincontent .pagination li[data-page=' + pagenr + ']').addClass('active');
  }

  for(var c = 0; c < summaryitems.length; c++)
  {
    if(c >= pagenr*summaryitemspp && c < pagenr*summaryitemspp + summaryitemspp)
    {
      summaryitems[c].setStyle('display',null);
      (function(){ summaryitems[this].setStyle('opacity',1);}.bind(c)).delay(1);
    }
    else
    {
      summaryitems[c].setStyles({'display':'none', 'opacity': 0});
    }
  }

}


function showMobileMenu(ev)
{
  $('mobilemenuholder').setStyle('display','block');
  (function(){ $(document.documentElement).addClass('showmenu'); }).delay(1);
}

function hideMobileMenu(ev)
{
  $(document.documentElement).removeClass('showmenu');
  setTimeout(function(){ $('mobilemenuholder').setStyle('display',null); },300);
  //close all foldouts and preset foldout for active items
  $('mobilemenu').getElements('.foldout').removeClass('foldout');
  $('mobilemenu').getElements('.active.haschildren').addClass('foldout');
}

function toggleSideNavItems(ev)
{
  var wrappernode = this.getParent('li');
  if(!wrappernode)
    return;

  if(wrappernode.hasClass('haschildren'))
  {
    if(wrappernode.hasClass('foldout'))
    {
      //just follow link
    }
    else
    { //foldout subitems
      ev.stop();

      var pathnodes = [];
      var pathnode = wrappernode.getParent('li.foldout');
      while(pathnode)
      {
        pathnodes.push(pathnode);
        pathnode = pathnode.getParent('li.foldout');
      }

      wrappernode.getParent('.menu').getElements('li.foldout').each(function(node)
      { //close items if not in current item path
        if(!pathnodes.contains(node))
          node.removeClass('foldout');
      });

      wrappernode.addClass('foldout');
    }
  }
}

function showVideoDialog(ev)
{
  if( !consenthandler.hasConsent("thirdparty"))
    return;

  if(!window.videodialog)
  {
    var dialognode = new Element('div',{ 'id' : 'videodialog'});
    window.videodialog = $wh.PopupManager.createFromElement(dialognode);
    window.videodialog.addEvent('aftershow',startVideo);
    window.videodialog.addEvent('afterhide',stopVideo);
  }
  var videonode = ev.target.hasClass('video') ? ev.target : ev.target.getParent('.video');
  videonode.clone().addClass('wh-video').inject(window.videodialog.getElement('#videodialog'));
  window.videodialog.show();
}
function startVideo()
{
  $wh.applyReplaceableComponents($('videodialog').getFirst('.wh-video'));
}
function stopVideo()
{
  window.videodialog.getElement('#videodialog').empty();
}

function initPhotoAlbum()
{
  var node = $('pictures');
  var slidesettings = { autoplay: false
                      , method: 'slide-horizontal'
                      , slideduration: 500
                      , delay: 5000
                      , slide_selectedclass: 'active'
                      , prevbutton: node.getElement('.previous')
                      , prevbutton_disabledclass: 'disabled'
                      , prevbutton_enabledclass: 'enabled'
                      , nextbutton: node.getElement('.next')
                      , nextbutton_disabledclass: 'disabled'
                      , nextbutton_enabledclass: 'enabled'
                      };
  var slideshow = new $wh.Slideshow(node, slidesettings);

  var infonode = $('picturesinfo');
  infonode.getElement('.title').set('text',slideshow.slides[0].get('title'));
  infonode.getElement('.nr').set('text','1 / ' + slideshow.slides.length);

  if(slideshow.slides.length <= 1)
  {
      node.getElements('.next, .previous').destroy(); //No left/right
  }
  else
  {

    slideshow.addEvent('startslide', function(info)
    {
      infonode.addClass('hide');
    });

    slideshow.addEvent('endslide', function(info)
    {
      infonode.getElement('.title').set('text',slideshow.slides[slideshow.currentpos].get('title'));
      infonode.getElement('.nr').set('text',( slideshow.currentpos + 1 ) + ' / ' + slideshow.slides.length);
      infonode.removeClass('hide');
    });
    //key navigation
    var keynav = new Keyboard({ defaultEventType: 'keydown'
                              , events: { 'left': function()
                                          {
                                            if(!$(document.documentElement).hasClass('lightbox') && !$(document.documentElement).hasClass('wh-modal-popup-active'))
                                              slideshow.gotoSlideRelative(-1,true);
                                          }
                                        , 'right': function()
                                          {
                                            if(!$(document.documentElement).hasClass('lightbox') && !$(document.documentElement).hasClass('wh-modal-popup-active'))
                                              slideshow.gotoSlideRelative(1,true);
                                          }
                                        }
                             });
    keynav.activate();
  }
}


var cTabsContainer = new Class(
{
  tabs : []
, node : null
, initialize : function(node)
  {
    this.node = node;

    this.node.getChildren('li').each(function(tabnode, i){

      var tabdata = { "node"    : tabnode
                    , "title"   : tabnode.getElement(".tab-title")
                    , "content" : tabnode.getElement(".tab-content")
                    };

      this.tabs.push( tabdata );

      tabdata.title.addEvent("click", this.onTabClick.bind(this, i));

    }.bind(this));

   // window.addEvent("resize", this.setMinHeight.bind(this));
   // this.setMinHeight();
  }

, setMinHeight: function()
  {
    var minheight = 0;

    this.tabs.each(function(tabdata){

      tabdata.content.setStyle("display", "block");

      var h = tabdata.title.clientHeight + tabdata.content.clientHeight;
      if( h > minheight )
        minheight = h;

      tabdata.content.setStyle("display", "");

    }.bind(this));

    this.node.setStyle("min-height", minheight + "px");
  }

, onTabClick: function(i, ev)
  {
    if( this.tabs[i].node.hasClass("active") )
      return;

    this.tabs.each(function(tabdata){
      tabdata.node.removeClass("active");
    }.bind(this));

    this.tabs[i].node.addClass("active");
  }
});


var headerMap = new Class(
{ Extends: $wh.GoogleMap
, coordstr : ''
, geocoder : null
, markers : []
, distance : 0
, geolocation : null
, foundnearby : false
, locationdetailnodes: null
, resultscontainer : null
, loadmap: false
, initialize : function()
  {
    this.loadmap = consenthandler.hasConsent("thirdparty");

    var iconlist =  [ { name:     "prikpost"
                      , icon:     $wh.config.imgroot + "markers/prikpost36.png"
                      , shadow:   ""
                      , anchorx:  13
                      , anchory:  36
                      , scaledwidth: 27
                      , scaledheight: 36
                      , optimized: false
                      }
                     ,{ name:     "trombosedienst"
                      , icon:     $wh.config.imgroot + "markers/trombosedienst36.png"
                      , shadow:   ""
                      , anchorx:  13
                      , anchory:  36
                      , scaledwidth: 27
                      , scaledheight: 36
                      , optimized: false
                      }
                     ,{ name:     "afnamelaboratorium"
                      , icon:     $wh.config.imgroot + "markers/afnamelaboratorium36.png"
                      , shadow:   ""
                      , anchorx:  13
                      , anchory:  36
                      , scaledwidth: 27
                      , scaledheight: 36
                      , optimized: false
                      }
                     ,{ name:     "prikpostclosed"
                      , icon:     $wh.config.imgroot + "markers/prikpost-closed36.png"
                      , shadow:   ""
                      , anchorx:  13
                      , anchory:  36
                      , scaledwidth: 27
                      , scaledheight: 36
                      , optimized: false
                      }
                     ,{ name:     "trombosedienstclosed"
                      , icon:     $wh.config.imgroot + "markers/trombosedienst-closed36.png"
                      , shadow:   ""
                      , anchorx:  13
                      , anchory:  36
                      , scaledwidth: 27
                      , scaledheight: 36
                      , optimized: false
                      }
                     ,{ name:     "afnamelaboratoriumclosed"
                      , icon:     $wh.config.imgroot + "markers/afnamelaboratorium-closed36.png"
                      , shadow:   ""
                      , anchorx:  13
                      , anchory:  36
                      , scaledwidth: 27
                      , scaledheight: 36
                      , optimized: false
                      }  ];

    var mapnode = $('gmap');
    this.coordstr = '52.2637811,6.7698322';

    this.resultscontainer = $('mapresults');
    this.thuisprikroutesnode = document.getElementById("thuisprikroutes");

    if( this.loadmap )
    {
      if($('locationsearchform'))
        $('locationsearchform').addEvent('submit', this.searchNearby.bind(this));

      this.addEvent("initialized", this.onHeaderMapInit.bind(this));
      this.parent( mapnode
                 , false
                 , { language :     'nl'
                   , iconsize:      36
                   , icons:         iconlist
                   , maptype:       'roadmap'
                   , markermanager: false
                   , moveable:      true
                   , showcontrols:  false
                   , onOverlayclick:this.onMapOverlayClick.bind(this)
                   , center:        this.coordstr
                   , zoom:          16
                   , scrollwheel:   true
                   , apikey:        whintegration.config.site.googleapikey
                   }
                 );

    }

    $('printlocations').addEvent('click',this.openPrintVersion.bind(this));
  }

, openPrintVersion: function()
  {
    if(!$('printlocations').hasClass('enabled'))
      return;

    var latstr = '';
    var lngstr = '';
    if(this.geolocation)
    {
      latstr = this.geolocation.lat();
      lngstr = this.geolocation.lng();
    }

    document.documentElement.addClass("printprikposten");
  }

, onMapOverlayClick : function(marker)
  {
    this.openInfoWindow(marker);
  }

, searchNearby : function(ev)
  {
    ev.stop();

    this.foundnearby = false;

    var location = $('nearbyform_zip').value;
    location = location.replace(/[^A-Za-z0-9-,]/g," ").trim();

    //var zip = this.validateZip(location);
    this.distance = 1*($('nearbyform_distance').value.replace(/([^0-9])/g,''));
    this.geolocation = null;

    if( this.thuisprikroutesnode )
      this.hideThuisprikroute();

    if(location == '')
    {//reset
      this.showLocations(this.markers);
      return;
    }

    if(this.geocoder && location != '')
    {

      if(this.distance <= 0)
      { //set distance to 5km
        this.distance = 5;
        $('nearbyform_distance').set('value',5).fireEvent('wh-refresh');
      }

      let searchfor = { address: location
                      , componentRestrictions: { country: 'NL' }
                      };

      this.geocoder.geocode( searchfor, function (results, status)
      {
        if (status == google.maps.GeocoderStatus.OK)
        {
          //results is a non exact list, so check for results only in 'NL'
          var resultindex = -1;
          var validresults = [];
          for(var r = 0; r < results.length; r++)
          {
            var isvalid  = false;
            var province = "";
            var postalcodes = [];
            for(var c = 0; c < results[r].address_components.length; c++)
            {
              if(results[r].address_components[c].types.contains('country'))
                isvalid = results[r].address_components[c].short_name == 'NL';

              if(results[r].address_components[c].types.contains('administrative_area_level_1'))
                province = results[r].address_components[c].short_name;

              if(results[r].address_components[c].types.contains('postal_code'))
              {
                var postalcode = results[r].address_components[c].long_name;
                if( postalcode.length >= 4 )
                  postalcodes.push(parseInt(postalcode.substr(0,4)));
              }
            }

            if(isvalid)
            {//set priority for provinces (first OV, second GE)
              var priority = province == 'OV' ? 0 : (province == 'GE' ? 1 : 3);
              validresults.push({ 'index' : r, 'priority' : priority });
            }
          }

          if(validresults.length)
          {
            validresults = validresults.sort(function(a,b){return a.priority - b.priority;});
            resultindex = validresults[0].index;

            if( postalcodes.length && this.thuisprikroutesnode )
              this.showThuisprikroute(postalcodes);

            this.foundnearby = true;
          }

          var visiblemarkers = [];
          if(resultindex != -1)
          {
            this.geolocation = results[resultindex].geometry.location;
            this.map.setCenter(this.geolocation);
            for(var m = 0; m < this.markers.length; m++)
            {
              this.markers[m].distance = this.getDistance(this.markers[m].latlng,this.geolocation);
              if(this.markers[m].distance <= this.distance || this.distance <= 0)
                visiblemarkers.push(this.markers[m]);
            }
            visiblemarkers.sort(this.sortOnDistance);
          }

          this.showLocations(visiblemarkers);

          if(resultindex != -1)
            this.offsetCenter(-115,0);

         }
         else
         { //noresults
           this.showLocations([]);
          // console.log("Geocoding failed: " + status);
         }

      }.bind(this));
    }

  }

, hideThuisprikroute: function()
  {
    dompack.empty( this.thuisprikroutesnode );
    this.thuisprikroutesnode.classList.remove("active");
  }

, showThuisprikroute: function(postalcodes)
  {
    let info =  $wh.config.obj.thuisprikroutes;
    let foundroutes = [];
    for(let c = 0; c < info.routes.length; ++c)
    {
      if( postalcodes.indexOf(info.routes[c].postcode) > -1 )
        foundroutes.push(info.routes[c]);
    }

    if( foundroutes.length )
    {
      if( info.title )
        this.thuisprikroutesnode.appendChild(<div class="title">{info.title}</div>);

      if( info.description )
        this.thuisprikroutesnode.appendChild(<div class="description">{info.description}</div>);

      for(let c = 0; c < foundroutes.length; ++c)
      {
        this.thuisprikroutesnode.appendChild(<div class="postcode">{foundroutes[c].postcode + " - " + this.short2Fullweekdays(foundroutes[c].days)}</div>);
        if( foundroutes[c].exceptions.length )
        {
          this.thuisprikroutesnode.appendChild( <div class="exception">
                                                  <b>Uitgezonderd:</b><br />
                                                  <ul class="unordered">
                                                    {foundroutes[c].exceptions.map(item => <li>{item.title + ": " + this.short2Fullweekdays(item.days)}</li>)}
                                                  </ul>
                                                </div>);
        }
      }

      if( info.link )
        this.thuisprikroutesnode.appendChild(<a class="link" href={info.link}>{info.linktext}</a>);

      this.thuisprikroutesnode.classList.add("active");
    }
  }

, short2Fullweekdays(weekdays)
  {
    let days = weekdays.split("/");
    for( let c = 0; c < days.length; ++c )
    {
      if( days[c] == "ma" )
        days[c] = "maandag";
      else if( days[c] == "di" )
        days[c] = "dinsdag";
      else if( days[c] == "wo" )
        days[c] = "woensdag";
      else if( days[c] == "do" )
        days[c] = "donderdag";
      else if( days[c] == "vr" )
        days[c] = "vrijdag";
      else if( days[c] == "za" )
        days[c] = "zaterdag";
      else if( days[c] == "zo" )
        days[c] = "zondag";
    }
    return days.join(", ");
  }

, offsetCenter : function(offsetx,offsety)
  {
    // offsetx is the distance you want that point to move to the right, in pixels
    // offsety is the distance you want that point to move upwards, in pixels
    // offset can be negative

    var latlng = this.map.getCenter();

    var scale = Math.pow(2, this.map.getZoom());
    var nw = new google.maps.LatLng(
      this.map.getBounds().getNorthEast().lat(),
      this.map.getBounds().getSouthWest().lng()
    );

    var worldCoordinateCenter = this.map.getProjection().fromLatLngToPoint(latlng);
    var pixelOffset = new google.maps.Point((offsetx/scale) || 0,(offsety/scale) || 0)

    var worldCoordinateNewCenter = new google.maps.Point(
      worldCoordinateCenter.x - pixelOffset.x,
      worldCoordinateCenter.y + pixelOffset.y
    );

    var newCenter = this.map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

    this.map.panTo(newCenter);
  }

, showLocations : function(locations)
  {
    var rowkeys = '';
    if(locations.length > 0)
    {
      rowkeys = locations[0].rowkey;

      var latmin = locations[0].lat;
      var latmax = locations[0].lat;
      var lngmin = locations[0].lng;
      var lngmax = locations[0].lng;
      for(var c = 1; c < locations.length; c++)
      {
        rowkeys+='|' + locations[c].rowkey;

        if(locations[c].lat >  latmax)
          latmax = locations[c].lat;
        else if(locations[c].lat <  latmin)
          latmin = locations[c].lat;

        if(locations[c].lng >  lngmax)
          lngmax = locations[c].lng;
        else if(locations[c].lng <  lngmin)
          lngmin = locations[c].lng;
      }

      rowkeys = '|' + rowkeys + '|';

      var bounds = new google.maps.LatLngBounds(new google.maps.LatLng(latmin, lngmin),new google.maps.LatLng(latmax, lngmax));
      this.fitBounds(bounds);
   //   if(this.map.getZoom() > 19)
   //       this.map.setZoom(19);
    }

    this.updateAllOverlays(locations);

    this.resultscontainer.empty();
    $('resultsfeedback').set('text','');
    this.closeInfoWindow();
    $('printlocations').toggleClass('enabled',locations.length > 0);
    if(locations.length > 0)
    { //show details overview
      var listnode = new Element('ul').inject(this.resultscontainer);

      var feedbackstr = locations.length + (locations.length > 1 ? ' locaties gevonden' : ' locatie gevonden');
      $('resultsfeedback').set('text', feedbackstr );

      if( this.foundnearby )
      { //show locations in list on distance ascending
        for(var l = locations.length - 1; l >= 0 ; l--)
        {
          for(var c = 0; c < this.locationdetailnodes.length; c++)
          {
            if( locations[l].rowkey == parseInt( this.locationdetailnodes[c].get('data-rowkey') ))
              listnode.adopt(this.locationdetailnodes[c]);
          }
        }
      }
      else
      {
        for(var c = 0; c < this.locationdetailnodes.length; c++)
        {
          var rowkey = this.locationdetailnodes[c].get('data-rowkey');
          if(rowkeys.indexOf('|' + rowkey + '|') != -1)
            listnode.adopt(this.locationdetailnodes[c]);
        }
      }
    }
    else
    {
      $('resultsfeedback').set('text', 'Geen locatie gevonden' );
    }

  }

, sortOnDistance : function(a,b)
  {
    return (b.distance - a.distance);
  }

, getDistance : function(pos1, pos2)
  {
    var lat1 = pos1.lat();
    var lng1 = pos1.lng();

    var lat2 = pos2.lat();
    var lng2 = pos2.lng();

    var radfactor = Math.PI / 180;
    var r = 6371; // earth radius in km

    var dlat = (lat2 - lat1) * radfactor;
    var dlon = (lng2 - lng1) * radfactor;

    var a = Math.sin(dlat / 2) * Math.sin(dlat / 2)
          + Math.cos(lat1 * radfactor) * Math.cos(lat2 * radfactor)
          * Math.sin(dlon / 2) * Math.sin(dlon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return Math.round(r * c);
  }

, validateZip : function(zip)
  {
    zip = zip.toUpperCase().replace(/([^0-9A-Z])/g,'');
    if(zip.length < 4)
      return '';

    var nr = zip.substr(0,4).replace(/([^0-9])/g,'');
    var letters = zip.substr(4,2).replace(/([^A-Z\.])/g,'');

    if(nr.length==4)
      return nr + (letters.length == 2 ? letters : '');
    else
      return '';
  }

, onMapClick : function(ev)
  {
    this.parent(ev);
  }

, onHeaderMapInit : function()
  {
    if(this.resultscontainer && this.loadmap)
    {//load preformatted result details
      this.locationdetailnodes = this.resultscontainer.getElements('li');
      this.resultscontainer.empty();
    }

    var markercoord = $wh.GoogleMap.stringToLatLng(this.coordstr);

    this.geocoder = new google.maps.Geocoder();

    this.map.setOptions({ panControl: false
                        , panControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT }
                        , zoomControl: true
                        , streetViewControl: false
                        , zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL, position: google.maps.ControlPosition.RIGHT_TOP }
                        , mapTypeControl: false
                        , maxZoom : 17
                        , minZoom : 9
                        });


    let today = new Date().format('%Y-%m-%d');
//    console.log( today );

    for(var m = 0; m < $wh.config.obj.locations.length; m++)
    {
      var marker = $wh.config.obj.locations[m];
      var coord = $wh.GoogleMap.stringToLatLng(marker.location);

      if( marker.exceptions && marker.exceptionsdata.startdate <= today && marker.exceptionsdata.enddate >= today )
        marker.allclosed = marker.exceptionsdata.allclosed;

      marker.distance  = -1;
      marker.icon      = marker.type + (marker.allclosed ? "closed" : "");
      marker.type      = "marker";
      marker.lat       = coord.lat();
      marker.lng       = coord.lng();
      marker.latlng    = coord;
      marker.selectable= true;

      var directionsurl = 'https://www.google.com/maps/dir//' + marker.address + '/@' + marker.location + ',15z?hl=nl';

      var extranote = '';
      if( marker.exceptions )
        extranote = '<span class="extranote bgmagenta">Let op: gewijzigde openingstijden op <br />' + marker.exceptions + '</span>';

      marker.overlayhtml  = '<div class="wh-infowindow">';
      marker.overlayhtml += '<a href="#' + marker.hash +'" class="title">' + marker.name + extranote + '</a>';
      if( marker.appointmentlink )
        marker.overlayhtml += '<a href="' + marker.appointmentlink + '" target="appointment" class="route bgblue bghoverdark">Maak een afspraak<span class="arrow fa fa-chevron-right bgdark"></span></a>';
      marker.overlayhtml += '<a href="' + directionsurl + '" target="routekaart" class="route bggreen bghoverdark">Route<span class="arrow fa fa-chevron-right bgdark"></span></a>';
      marker.overlayhtml += '</div>';

      this.markers.push(marker);
    }

    if(this.markers.length)
      this.showLocations(this.markers);

    this.offsetCenter(-115,0);
  }

});


function setWidgetGrid()
{
  var widgetscontainer = $('widgets')
  if(!widgetscontainer || widgetscontainer.getChildren('.widget').length <= 2)
    return;

  var containerwidth = widgetscontainer.getSize().x;

  var cols = widgetscontainer.hasClass('threecolmax') ? 3 : 4;

  if(!($('sidenav') && $('sidenav').getStyle('display') == 'block') || containerwidth < 870)
    cols = containerwidth > 650 ? 3 : (containerwidth > 500 ? 2 : 1);

  var masonry = $('widgets').retrieve("masonry");
  if(!masonry)
  {
    masonry = new $wh.Masonry( widgetscontainer
                               , { columns:  cols
                                 , gutter_x: 0
                                 , gutter_y: 0
                                 , items:    ".widget"
                                 });
    widgetscontainer.store("masonry", masonry);
  }
  else
  {
    masonry.setOptions({ columns: cols });
    masonry.refresh();
  }
}


function onHeaderPreloadReady(viewmode)
{
  this.each(function(imagenode)
  {
    var imgsrc = imagenode.get('data-' + viewmode);
    imagenode.setAttribute('src',imgsrc);
  });
}

function onResize()
{
  var slidesnode = $('headerslides');
  if(slidesnode)
  {
    var wrappersize = slidesnode.getParent().getSize();
    var viewmode = wrappersize.x > 920 ? 'desktop' : (wrappersize.x > 750 ? 'tablet' : 'mobile');
    var curmode = slidesnode.retrieve('viewmode');
    if(!curmode || curmode != viewmode)
    {
      var assets = [];
      var imgnodes = slidesnode.getElements('img');
      imgnodes.each(function(imagenode)
      {
        var imgsrc = imagenode.get('data-' + viewmode);
        if(imagenode.getAttribute('src') != imgsrc)
        {
          assets.push(new $wh.PreloadableImage(imgsrc));
        }
      });

      if(assets.length)
      {
        new $wh.Preloader( assets
                         , { onComplete:  onHeaderPreloadReady.bind(imgnodes, viewmode)
                          // , timeout: 10000
                           }
                         );
      }

      slidesnode.store('viewmode',viewmode)
    }
    var imgsize = slidesnode.get('data-' + viewmode).split(',');
    var cover = $wh.getCoverCoordinates( imgsize[0].toInt()
                                       , imgsize[1].toInt()
                                       , wrappersize.x
                                       , wrappersize.y
                                       , false
                                       );
    slidesnode.setStyles({ 'top' : cover.top + 'px', 'left' : cover.left + 'px', 'width' : cover.width + 'px', 'height' : cover.height + 'px'});
  }

  if($('mobilemenu-open').getStyle('display') != 'block')
    hideMobileMenu();

  setWidgetGrid();

  var rtddocnode = $('maincontent');
  if(rtddocnode)
  {
    var rtdwidth = rtddocnode.getSize().x;
    rtddocnode.getElements('p img').each(function(imgnode)
    {
      var maxwidth = imgnode.getParent('p').getSize().x;
      maxwidth = !maxwidth || maxwidth > rtdwidth ? rtdwidth : maxwidth;
      var orgwidth = Number(imgnode.getAttribute('width'));
      imgnode.toggleClass('fullwidth',(orgwidth && orgwidth >= maxwidth));
    });

    $$('.embeddedobject.imageslist').each(function(node)
    {
      var mygrid = node.retrieve('grid');
      if( mygrid)
      {
        var gridnode = node.getChildren('div');//hide element for correct measurement
        gridnode.setStyle('display','none');
        var w = node.getSize().x;
        gridnode.setStyle('display',null);
        mygrid.setOptions({ 'width' : w });
        mygrid.refresh();
      }
    });
  }

  //FAQ
  $$('.wh-accordion-group').each(function(sectionnode)
  {
    var qheight = sectionnode.getElement('.wh-accordion-question').getSize().y;
    if(sectionnode.hasClass('active'))
    {
      var contentheight = sectionnode.getElement('.wh-accordion-anwer').getSize().y;
      sectionnode.setStyle('height', (qheight + contentheight));
    }
    else
    {
      sectionnode.setStyle('height', qheight);
    }
  });

  var messagenode = $('globalmessage_holder');
  if(messagenode && messagenode.getSize().y > 0)
  {
    var innerheight = messagenode.getElement('#globalmessage_inner').getSize().y;
    var maxheight = $(window).getSize().y;
    if(innerheight > maxheight)
    {
      innerheight = maxheight;
      messagenode.addClass('scrollable');
    }
    else
    {
      messagenode.removeClass('scrollable');
    }
    messagenode.setStyles({'height':'auto','max-height': maxheight + 'px'});
  }
}

function initFaqPage()
{
  var qnode = null;
  var anodes = [];
  $('maincontent').getChildren().each(function(node)
  {
    if(node.nodeName == 'H2')
    {
      if(qnode && anodes.length > 0)
        setupFaqGroup(qnode, anodes);

      qnode = node;
      anodes = [];
    }
    else
    {
      anodes.push(node);
    }
  });

  if(qnode && anodes.length > 0)
    setupFaqGroup(qnode, anodes);
}

function setupFaqGroup(qnode, anodes)
{
  var sectionnode = new Element('div', { 'class' : 'wh-accordion-group' }).inject(qnode,'before');
  qnode.addClass('wh-accordion-question').inject(sectionnode);

  new Element('span',{ 'class' : 'arrow fa fa-angle-down' }).inject(qnode);
  new Element('span',{ 'class' : 'arrow down fa fa-angle-up fggreen' }).inject(qnode);

  var foldednode = new Element('div', { 'class' : 'wh-accordion-anwer' }).inject(sectionnode).adopt(anodes);

  sectionnode.setStyle('height', qnode.getSize().y + 'px');
  foldednode.setStyle('display','block');

  qnode.addEvent('click',function(ev)
  {
    var qheight = qnode.getSize().y;
    if(sectionnode.hasClass('active'))
    {
      sectionnode.setStyle('height', qheight);
    }
    else
    {
      var contentheight = ev.target.getParent('.wh-accordion-group').getElement('.wh-accordion-anwer').getSize().y;
      sectionnode.setStyle('height', (qheight + contentheight));
    }
    sectionnode.toggleClass('active');
  });
}

dompack.register("#mapresults .note", node => replaceURLWithHTMLLinks(node) );

function replaceURLWithHTMLLinks(node)
{
  let text = node.textContent;
  if (text)
  {
    text = text.replace(
      /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{1,40}(\/\S*)?)/gi,
      function(url){
        var full_url = url;
        if (!full_url.match('^https?:\/\/'))
          full_url = 'http://' + full_url;

        return '<a href="' + full_url + '" target="_blank">' + url + '</a>';
      }
    )

    node.innerHTML = text;
  }
}


function closeTopSearch(ev)
{
  if(ev.target == $('topsearch') || ev.target.getParent('#topsearch'))
    return;

  $('topsearch').removeClass('aftershow');
  $('topsearch').removeClass('active');
  $(document.body).removeEvent('click',closeTopSearch);
  if(window.touch_enabled)
    $(document.body).removeEvent('touchstart',closeTopSearch);
}

window.addEvent("domready", medlon_init);

})(document.id); //end mootools/scope wrapper
