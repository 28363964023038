import "./telefoongids.scss";
import * as dompack from "dompack";

class cPhonelistSearch
{
  constructor(node)
  {
    this.node = node;

    this.items = document.querySelectorAll("#phonelist tr.doindex");
    this.feedbacknode = document.querySelector("#phonelistdescr .results");
    this.resultstable = document.querySelector("#phonelistresults table");

    node.addEventListener("submit", ev => {
      dompack.stop(ev);
      this.doSearch();
    });

    for( let node of document.querySelectorAll("#phonelist .backtooverview") )
      node.addEventListener("click", () => this.hideResults() );
  }

  hideResults()
  {
    document.documentElement.classList.remove("showphoneresults");
    this.removeResults();
  }

  removeResults()
  {
    let resnode = this.resultstable.querySelector("tbody");
    if( resnode )
      resnode.parentNode.removeChild(resnode);
  }

  doSearch()
  {
    let words = this.node.terms.value.trim();

    if( words == "" )
    {
      this.hideResults();
      return;
    }

    this.removeResults();

    let rescontainer = <tbody />;
    this.resultstable.appendChild(rescontainer);

    let results = [];
    let count = 0;
    let chkstr = words.toUpperCase();
    for( let node of this.items )
    {
      let found = false;
      for( let c = 0; c < node.children.length; ++ c )
      { //Check seperate cells
        let txt = node.children[c].textContent.toUpperCase();
        found = txt.indexOf(chkstr) > -1;
        if( found )
          break;
      }

      if( found )
      {
        ++count;
        rescontainer.appendChild( node.cloneNode(true) );
      }

      if( count == 1 )
        this.feedbacknode.textContent = "Er is " + count + " resultaat gevonden op zoekterm '" + words + "'";
      else
        this.feedbacknode.textContent = "Er zijn " + count + " resultaten gevonden op zoekterm '" + words + "'";
    }

    document.documentElement.classList.add("showphoneresults");
  }
}

dompack.register("#phonelist h3.tabletoggle", node => {
  node.addEventListener("click", () => {
    node.parentNode.classList.toggle("active");
  });
});

dompack.register("#phone_searchform", node => new cPhonelistSearch(node) )
