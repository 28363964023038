import "./cookiesettings.css";
import * as dompack from 'dompack';
import * as consenthandler from '@mod-publisher/js/analytics/consenthandler';
import * as dialogapi from 'dompack/api/dialog';

function setupCookieSettingsWidget(formnode)
{
  let inpoptions = formnode.querySelectorAll("input");
  inpoptions[0].checked = consenthandler.hasConsent("analytics");
  inpoptions[1].checked = consenthandler.hasConsent("thirdparty");

  for( let inpnode of inpoptions )
  {
    inpnode.addEventListener("change", ev => {
      if( inpnode.checked && inpnode.value != "analytics" )
        inpoptions[0].checked = true;//then also allow statistics

      if( !inpnode.checked && inpnode.value == "analytics" )
        inpoptions[1].checked = false;//then also disable thirdparty
    });
  }

  let redirecturl = new URL(location.href).searchParams.get("redirect");
  if(redirecturl && !redirecturl.startsWith(location.origin)) //no cross server redirects
    redirecturl = '';

  let btn = formnode.querySelector(".cookiesettings__submit");
  btn.addEventListener("click", async ev =>
  {
    let allowoptions = [];
    for( let inpnode of inpoptions )
    {
      if( inpnode.checked )
        allowoptions.push(inpnode.value);
    }
    consenthandler.setConsent(allowoptions);

    await dialogapi.runMessageBox("Uw voorkeuren zijn opgeslagen", [{ title: `OK` }]);

    if( redirecturl )
      document.location.href = redirecturl;
    else
      document.location.reload();
  });
}

dompack.register(".embeddedobject.cookiesettings", node => setupCookieSettingsWidget(node));
