import "./vademecum.scss";

import * as dompack from "dompack";
import * as preload from 'dompack/extra/preload';

class cVademecumIndex
{
  constructor( summarynode )
  {
    this.summarynode = summarynode;
    let indexnode = document.getElementById("vademecum-index");
    if( indexnode )
    {
      for( let letternode of indexnode.querySelectorAll(".letter") )
      {
        letternode.addEventListener("click", ev => {
          let letter = letternode.parentNode.dataset.letter;
          if( !letter )
            return;

          this.showNodeForLetter( letter );
        });
      }
    }

    for( let letternode of this.summarynode.querySelectorAll(".letter") )
      letternode.addEventListener("click", ev => this.toggle( letternode.parentNode ) );

    let initialactive = decodeURIComponent(document.location.hash.substr(1));
    if( initialactive != "" )
      this.showNodeForLetter( initialactive );
  }

  showNodeForLetter( letter )
  {
    let shownode = this.summarynode.querySelector("li[data-letter='" + letter + "']");
    if( shownode )
    {
      this.toggle( shownode, true );
      let nodepos = shownode.getBoundingClientRect().top - 85;
      window.scrollBy(0, nodepos);
    }
  }

  toggle( itemnode, setactive )
  {
    if( this.activenode && setactive && this.activenode == itemnode )
      return;

    if( this.activenode && this.activenode != itemnode )
      this.activenode.classList.remove("active");//close previous block


    if( this.activenode && this.activenode == itemnode )
    {
      itemnode.classList.remove("active");
      this.activenode = null;
      document.location.hash = "";
    }
    else
    {
      itemnode.classList.add("active");
      this.activenode = itemnode;
      document.location.hash = "#" + encodeURIComponent(this.activenode.dataset.letter);
    }
  }
}


let scrollabletables;

dompack.onDomReady(() =>
{
  scrollabletables = document.querySelectorAll("*[data-scrollablecontent]");

  let last_winx;
  window.addEventListener("resize", ev => {
    let winx = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if( last_winx && winx == last_winx )
      return;
    checkScrollableContent();
  });
  checkScrollableContent();
});

function checkScrollableContent()
{
  for( let node of scrollabletables )
  {
    let innernode = node.children[0];
    let contentnode = innernode.children[0];
    if( innernode.clientWidth < contentnode.clientWidth )
    {
      node.classList.add("scrollable");
      let pos1 = innernode.getBoundingClientRect();
      let pos2 = contentnode.getBoundingClientRect();

      let l = pos1.left - pos2.left;
      let r = pos1.right - pos2.right;

      if( r < 0 )
        node.classList.add("scrollable--right");
      else
        node.classList.remove("scrollable--right");

      if( l > 0 )
        node.classList.add("scrollable--left");
      else
        node.classList.remove("scrollable--left");
    }
    else
    {
      node.classList.remove("scrollable");
    }
  }
}

class cVademecumImages
{
  constructor( node )
  {
    this.activeidx = 0;

    this.slides = node.querySelectorAll(".images__slides > .image");
    this.largeimages = [];
    for( let slidenode of this.slides )
      this.largeimages.push( slidenode.dataset.image );

    let idx = 0;
    this.jumpbuttons = node.querySelectorAll(".jumpbuttons > span");
    for( let jmpnode of this.jumpbuttons )
    {
      let i = idx;
      jmpnode.addEventListener("click", ev => this.gotoSlide(i) );
      ++idx;
    }

    this.resizefn = this.onResize.bind(this);
    this.keyfn = this.onKeyDown.bind(this);

    for( let enlargenode of node.querySelectorAll(".enlarge, .images__slides") )
      enlargenode.addEventListener("click", ev => this.enlarge());
  }

  gotoSlide( idx )
  {
    if( idx == this.activeidx )
      return;

    this.slides[this.activeidx].classList.remove("active");
    this.jumpbuttons[this.activeidx].classList.remove("active");
    if( this.overlay )
      this.overlay_jumpbuttons[this.activeidx].classList.remove("active");

    this.activeidx = idx;

    this.slides[this.activeidx].classList.add("active");
    this.jumpbuttons[this.activeidx].classList.add("active");
    if( this.overlay )
    {
      this.overlay_jumpbuttons[this.activeidx].classList.add("active");
      this.preloadImage( this.largeimages[this.activeidx] );
    }
  }

  async preloadImage( imgsrc )
  {
    this.preloadedimage = await preload.promiseImage( imgsrc );
    if( this.preloadedimage && this.imgholder )
    {
      dompack.empty(this.imgholder);//remove previous image
      this.imgholder.classList.remove("image--loading");
      this.preloadedimage.node.style.opacity = 0;
      this.imgholder.appendChild( this.preloadedimage.node );
      this.imgholder.clientWidth;// force css update
      this.preloadedimage.node.style.opacity = 1;

      this.imgholder.style.paddingTop = (100*this.preloadedimage.height / this.preloadedimage.width) + "%";
      this.imgholder.style.maxWidth = this.preloadedimage.width + "px";

      this.onResize();
    }
  }

  onKeyDown(ev)
  {
    if( ev.keyCode == 39 )//arrow rigt
    {//next
      let idx = this.activeidx + 1;
      if( idx > this.slides.length - 1 )
        idx = 0;
      this.gotoSlide(idx);
    }
    else if( ev.keyCode == 37 ) //arrow left
    {//previous
      let idx = this.activeidx - 1;
      if( idx < 0 )
        idx = this.slides.length - 1;
      this.gotoSlide(idx);
    }
    else if( ev.keyCode == 27 ) //ESC
      this.closeOverlay();
  }

  onResize()
  {
    if( !this.imgholder.children.length )
      return;

    this.imgholder.style.width = "";

    //make sure image height fits
    let y1 = this.imgholder.clientHeight;
    let y0 = this.imgholder.parentNode.clientHeight;
    if( y1 > y0)
    {
      let perc = (y1 - y0) / y1;

      let maxw = this.imgholder.clientWidth * (1 - perc);
      this.imgholder.style.width = maxw + "px";
    }
  }

  enlarge()
  {
    if( this.overlay )
      return;//already loaded

    document.body.style.overflow = "hidden";

    this.overlay = dompack.create("div", {"id" : "vademecum-largeimages"});
    this.overlay.addEventListener("click", ev => this.onOverlayClose(ev) );
    document.body.appendChild(this.overlay);

    let closenode =  <span class="close fa-stack">
                      <span class="fa fa-angle-right fa-stack-1x"></span>
                      <span class="fa fa-angle-left fa-stack-1x"></span>
                     </span>;//
    this.overlay.appendChild(closenode);

    let centernode = dompack.create("div", {"className" : "holder"});
    this.overlay.appendChild(centernode);

    this.imgholder = dompack.create("div", {"className" : "largeimage"});
    centernode.appendChild(this.imgholder);

    this.overlay_jumpbuttons = [];
    if( this.largeimages.length > 1 )
    {
      let navnode = dompack.create("div", {"className" : "jumpbuttons"});
      centernode.appendChild(navnode);

      for( let i = 0; i < this.largeimages.length; ++i )
      {
        let jmpnode = dompack.create("span");
        if( i == this.activeidx )
          jmpnode.classList.add("active");
        jmpnode.addEventListener("click", ev => this.gotoSlide(i) );

        navnode.appendChild(jmpnode);
        this.overlay_jumpbuttons.push(jmpnode);
      }
    }

    this.preloadImage( this.largeimages[this.activeidx] );

    window.addEventListener("resize", this.resizefn );
    window.addEventListener("keydown", this.keyfn );
  }

  onOverlayClose( ev )
  {
    if( dompack.closest(ev.target,".holder") )
      return;

    this.closeOverlay();
  }

  closeOverlay()
  {
    window.removeEventListener("resize", this.resizefn );
    window.removeEventListener("keydown", this.keyfn );

    this.overlay.parentNode.removeChild(this.overlay);
    this.overlay = null;
    document.body.style.overflow = "";
  }
}

function replaceURLWithHTMLLinks(node)
{
  let text = node.textContent;
  if (text)
  {
    text = text.replace(
      /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{1,40}(\/\S*)?)/gi,
      function(url){
        var full_url = url;
        if (!full_url.match('^https?:\/\/'))
          full_url = 'http://' + full_url;

        return '<a href="' + full_url + '" target="_blank">' + url + '</a>';
      }
    );

    node.innerHTML = text;
  }
}



dompack.register("#vademecum-details .block__content td", node => replaceURLWithHTMLLinks(node) );
dompack.register("#vademecum-summary", node => new cVademecumIndex(node) );
dompack.register("#vademecum-details .images", node => new cVademecumImages(node) );
