require('designfiles/wh.form')
require('designfiles/wh.form.model.datefields')
;
/*! LOAD: wh.form, wh.form.model.datefields
!*/

(function($) { //mootools/scope wrapper
"use strict";

function fillAddress()
{
  var selectedoption = $$('#account_healthcareorg option')[$('account_healthcareorg').selectedIndex];
  if(selectedoption && selectedoption.hasAttribute('data-address'))
  {
    var address = JSON.decode(selectedoption.getAttribute("data-address"));
    ['city','street','nr_detail','zip'].forEach(function(propname)
    {
      $wh.changeValue('account_wrd_contact_address.' + propname,  address[propname]);
    });
  }

  if($('account_department'))
     $('account_department').set('required', selectedoption && selectedoption.hasAttribute('data-departmentrequired') ? 'required' : null);

  if($('account_employeenumber'))
  {
    var show = selectedoption && selectedoption.hasAttribute('data-employeenumberrequired');
    $('account_employeenumber').set('required', show  ? 'required' : null);
  }
}

function forms_init()
{
  if(!document.querySelector("div.coursepage"))
    return;

  $$("form.wh-form").each(function(form)
  {
    var handler = $wh.Form.setupHandler(form);
  });

  if($('momentslist'))
  {
    $$('#momentslist .month > .month-title').addEvent('click', function()
    {
      $$('#momentslist .month').removeClass('active');
      this.getParent('.month').addClass('active');
    });
  }

  var prevbutton = $("coursewizard-step2-prevbutton");
  if (prevbutton)
  {
    var prevlink = $("coursewizard-step2-prevlink");
    if (prevlink)
      prevlink.setStyle("display", "none");
    prevbutton.addEvent("click", function(event)
    {
      event.stop();

      var handler = prevbutton.getParent("form.wh-form").retrieve("wh-formhandler");
      handler.setData({ confirmed: "" });
      $('registrationform').setStyle('display','block');

      prevbutton.setStyle("display", "none");
      if (prevlink)
        prevlink.setStyle("display", "");
    });
  }

  //only enable prefill if the address is still empty
  if ($('account_wrd_contact_address.street') && !$('account_wrd_contact_address.street').value)
    $$('#account_healthcareorg').addEvent("change", fillAddress);
}

$wh.Form.models["wp.initials"] = new Class(
{ Extends: $wh.Form.InputFieldBase
, cleanup: function(value)
  {
    //strip any non-alphabetic chars
    value = value.toUpperCase().replace(/[^A-Z]/g,'');
    var outval='';
    for (var i = 0; i < value.length; ++i)
      outval += value[i] + '.';
    return outval;
  }
});


window.addEvent("domready", forms_init);

})(document.id); //end mootools/scope wrapper
