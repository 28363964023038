.coursewizard .wh-form
{
  x-overflow: auto; /* 'hack' making the form contain the floating buttons */
}
.coursewizard
{
  margin-top: 30px;
  padding: 20px 0;
}

  .coursewizard.linetop
, .coursewizard .linetop
{
  border-top: 1px solid #6cbc21;
}

.coursewizard .confirmmomentform
{
  margin-top: 20px;
}

  .coursewizard .heading1
, .coursewizard .heading2
, .coursewizard .heading3
{
  color: #6cbc21;
}

.coursewizard .month-title
{
  margin-top: 1px;
  background-color: #6cbc21;
  color: #fff;
  font: normal 20px/24px 'AutoPro-Regular', Arial;
  padding: 3px 10px;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
}
.coursewizard .month.active .month-title
{
  cursor: default;
}
.coursewizard .month-title > .fa
{
  position: absolute;
  top: 2px;
  right: 15px;
  font-size: 24px;
}
  .coursewizard .month-title > .fa-angle-up
, .coursewizard .month.active .month-title > .fa-angle-down
{
  display: none;
}
.coursewizard .month.active .month-title > .fa-angle-up-xx
{
  display: inline-block;
}

.coursewizard .month .moments
{
  display: none;
}
.coursewizard .month.active .moments
{
  display: block;
}

.coursewizard form > p
{
  margin-bottom: 10px;
}

.coursewizard .moment
{
  border-color: #6cbc21;
  border-style: solid;
  border-width: 1px 0 0 0;
  margin-top: 0;
  min-height: 80px; /* contain the .info block */
  padding: 15px 0;
  position: relative;
}
.coursewizard .moment + .moment
{
  margin-top: 0;
}

.coursewizard .radiobutton
{
  left: -30px;
  position: absolute;
}
.coursewizard .radiobutton input
{
}

.coursewizard .parts
{
  margin-left: 30px;
  margin-top: 5px;
  position: relative;
}

.coursewizard .info
{
  top: 50%;
  position: absolute;
  right: 0;
  height: 70px;
  margin-top: -35px;
  width: 50%;
}

@media (max-width:750px)
{
  .coursewizard .info
  {
    margin-top: 10px;
    position: static;
    width: 100%;
  }
}

.coursewizard .buttons
{
  padding-top: 20px;
  border-color: #6cbc21;
  border-style: solid;
  border-width: 1px 0 0 0;
  overflow: auto; /* 'hack' making the form contain the floating buttons */
}

.coursewizard p + .buttons
{
  margin-top: 20px;
}

  .coursewizard button
, .coursewizard .linkbutton
{
  position: relative;
  padding-right: 30px;
  min-width: 120px;
}
  .coursewizard button .fa
, .coursewizard .linkbutton .fa
{
  font-size: 23px;
  position: absolute;
  display: inline-block;
  top: 50%;
  margin-top: -12px;
  right: 10px;
}
.coursewizard .next
{
  float: right;
}

.coursewizard .previous
{
  float: left;
  padding-right: 10px;
  padding-left: 30px;
  text-align: right;
}
.coursewizard .previous .fa
{
  right: auto;
  left: 10px;
}

/* loginpage */
/*
  .loginblocks
, .accountform
{
  border-top: 1px solid #6cbc21;
  margin-top: 30px;
  padding: 20px 0;
}
*/
.loginblocks
{
  position: relative;
}
.loginblocks h2
{
  margin-bottom: 15px;
}
.loginblocks .noaccount h2
{
  margin-bottom: 31px;
}
  .loginblocks .loginform
, .loginblocks .noaccount
{
  width: 50%;
  display: table-cell;
  vertical-align: top;
  padding: 20px;
  border: 1px solid #6cbc21;
  background-color: #F1F6F4;
}
.loginblocks .noaccount p
{
  height: 88px;
}
.loginblocks .blockspacer
{
  display: table-cell;
  padding-left: 20px;
  width: 0;
}
.loginblocks .blockspacer .forgotpassword
{
  bottom: -10px;
  left: 21px;
  position: absolute;
}

.loginblocks p a
{
  color: #59280b;
}

@media (max-width:750px)
{
    .loginblocks .loginform
  , .loginblocks .noaccount
  {
    display: block;
    overflow: auto;
    width: 100%;
  }
  .loginblocks .blockspacer
  {
    display: block;
    padding-left: 21px;
    padding-top: 10px;
    padding-bottom: 15px;
    width: auto;
  }
  .loginblocks .blockspacer .forgotpassword
  {
    position: static;
  }
}

  .loginblocks form button
, .loginblocks a.linkbutton
{
  display: block;
  width: 100%;
  margin-top: 15px;
  text-align: left;
}
.loginblocks form input
{
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

/* accountpage*/
.accountform h2
{
  margin-bottom: 20px;
}
  .accountform input[type=text]
, .accountform input[type=email]
, .accountform input[type=password]
{
  display: block;
  width: 100%;
}
.accountform .fieldgroup
{
  margin-bottom: 15px;
  max-width: 560px;
}
.coursewizard table
{
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  margin: 0;
}
.coursewizard table tr > *
{
  width: 30%;
}
.coursewizard table tr > * + *
{
  width: 70%;
}
  .coursewizard table th
, .coursewizard table td
{
  vertical-align: top;
  text-align: left;
  padding-bottom: 10px;
}
.coursewizard table th
{
  padding-right: 10px;
  width: 200px;
}
.coursewizard table tr.done
{
  opacity: .7;
}

.widget .name
{
  color: #59280b;
  padding: 0 10px 10px 10px;
}

.whplugin-wrdauth-logout
{
  cursor: pointer;
  display: block;
  margin-top: 10px;
}

.whplugin-wrdauth-logout .fa
{
  color: #d33301;
  font-size: 20px;
}
.whplugin-wrdauth-logout .fa + .fa
{
  margin-right: 15px;
}

p.errormessage
{
  margin-bottom:10px;
  color:#c60a17;
}

.formerrors
{
  border:1px solid #c60a17;
  padding:10px;
  border-radius:5px;
  margin-bottom:15px;
  background:#ffe5e5;
}
