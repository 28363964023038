import "./login.scss";
import * as wrdauth from '@mod-wrd/js/auth';
import * as dompack from "dompack";

dompack.register("#headerholder > .inner", node => {
  if(wrdauth.getDefaultAuth().isLoggedIn())
  {
    let userinfo = wrdauth.getDefaultAuth().getUserInfo();
    if(!userinfo)
      return;

    let logoutnode = <a href="#" class="wh-wrdauth__logout fggreen">Uitloggen</a>;
    logoutnode.addEventListener("click", () => {wrdauth.getDefaultAuth().logout();});

    let metanav = <nav id="metanav" class="metanav--weblon">
                    <span class="fggreen">{userinfo.wrd_fullname}</span>
                    {logoutnode}
                  </nav>;
    node.appendChild(metanav);
  }
});
