import "./webforms.scss";
import * as forms from '@mod-publisher/js/forms';
import * as dompack from "dompack";
import {cSplitDateInput, cSplitTimeInput} from '../../shared/splitdatetimeinput';
import * as dialog from 'dompack/components/dialog';
import * as dialogapi from 'dompack/api/dialog';
import './dialog.scss';

forms.setup({ validate: true });

//Optionally: replaces upload fields with a nicer and edit-supporting version
import UploadField from '@mod-publisher/js/forms/fields/upload';
dompack.register(".wh-form__upload", node => new UploadField(node));

/*
//Enable the imgedit and/or rtd fields:
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));

import RTDField from '@mod-publisher/js/forms/fields/rtd';
dompack.register(".wh-form__rtd", node => new RTDField(node));
*/

dialogapi.setupDialogs(options => dialog.createDialog('medlon-dialog', options));

dompack.register(".wh-styledinput input[type=time]", node => new cSplitTimeInput(node));
dompack.register(".wh-styledinput input[type=date]", node => new cSplitDateInput(node));

//Styled (native) pulldown: add wrapper around select element + arrow for css-styling
dompack.register(".wh-form select", selectnode => {
  let wrappernode = dompack.closest(selectnode, ".wh-form__pulldown-styled");
  if( !wrappernode )
  { //if not already has styling wrapper, add..
    wrappernode = <div class="wh-form__pulldown-styled" />;
    selectnode.parentNode.insertBefore(wrappernode, selectnode);
    wrappernode.appendChild(selectnode);
    wrappernode.appendChild(<span class="arrow fa fa-angle-down" />);
  }

  if (MutationObserver)
  {
    if( selectnode.disabled )
      wrappernode.classList.add("wh-form__pulldown-styled--disabled");
    new MutationObserver(function(){
      dompack.toggleClass(wrappernode, "wh-form__pulldown-styled--disabled", selectnode.disabled);
    }).observe(selectnode, {subtree: false, attributes: true, characterData: false});
  }

});
