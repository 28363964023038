
/* radiobtn */
.wh-radiobutton
{
  background: transparent url(../web/img/radio.png) no-repeat 0 0;
  height: 20px;
  width: 20px;
  outline: none;
  position: relative;
  margin: 5px 3px 5px 0;
}
.wh-radiobutton.wh-checked
{
  background-position: 0 -20px;
}
.wh-radiobutton.wh-disabled
{
  background-position: 0 -40px;
}
.wh-radiobutton.wh-disabled.wh-checked
{
  background-position: 0 -60px;
}

/* checkbox */
.wh-checkbox
{
  background: transparent url(../web/img/checkbox.png) no-repeat 0 0;
  height: 20px;
  width: 20px;
  outline: none;
  position: relative;
  margin: 5px 3px 5px 0;
}
.wh-checkbox.wh-checked
{
  background-position: 0 -20px;
}
.wh-checkbox.wh-disabled
{
  background-position: 0 -40px;
}
.wh-checkbox.wh-disabled.wh-checked
{
  background-position: 0 -60px;
}

/* select */
.wh-pulldown
{
  background-color: #fff;
  outline: none;
  position: relative;
  height: 33px;
  border: 1px solid #59280b;
  padding: 6px 10px;
}
.whwp-survey .wh-pulldown
{
  border: 1px solid #59280b;
  height: 26px;
  line-height: 24px;
}
.wh-pulldown > .value
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.wh-pulldown .arrow
{
  position: absolute;
  right: 0;
  top: 0;
  width: 26px;
  height: 33px;
  padding: 0;
}
.wh-pulldown .arrow:after
{
  display: inline-block;
  font: normal normal normal 28px/28px FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  content: "\f107";
  padding: 0 0 0 0;
}
#locationsearchform .wh-pulldown .arrow:after
{
  padding: 6px 0 0 0;
}

.wh-pulldown > .wh-pulldown-values
{
  display: none;
}
.wh-pulldown-values
{
  margin: 0;
  border: 0 none;
  background-color: #fff;
  min-width: 165px;
  border: 1px solid #59280b;
  border-top: 0 none;
}
#nearbyform_distance-values.wh-pulldown-values
{
  width: 170px !important;
  border: none 0;
}
.wh-pulldown-values li
{
  padding: 0 6px;
  font-size: 14px;
  color: #000;
  line-height: 30px;
  text-align: left;
}
  .wh-pulldown-values li:hover
, .wh-pulldown-values li.selected
{
  background-color: #81aa94;
  color: #fff;
}

/**/
.wh-form
{
  position: relative;
}
  .wh-form input
, .wh-form textarea
, .wh-form select
{
  color: #59280b;
  font: 15px/21px Arial,Helvetica,Sans-Serif;
  -webkit-appearance: none;
  border-radius: 0;
}
  .wh-form input[type='text']
, .wh-form input[type='email']
, .wh-form input[type='password']
{
  height: 33px;
  border: 1px solid #59280b;
  background-color: #fff;
  padding: 6px 10px;
}
  .wh-form button
, .linkbutton
{
  color: #fff;
  padding: 4px 10px 6px;
  height: 38px;
  line-height: 38px;
  border: 0 none;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 0;
  font: normal 18px/30px 'AutoPro-Regular', Arial;
  -webkit-appearance: none;
  transition: opacity 0.3s;
  text-decoration: none;
}
  .wh-form button.next > span
, .linkbutton.next > span
{
  display: inline-block;
  padding-left: 5px;
}
  .wh-form button.previous > span
, .linkbutton.previous > span
{
  display: inline-block;
  padding-right: 5px;
}
  .wh-form button:hover
, .linkbutton:hover
{
  opacity: 0.9;
}
.wh-form textarea
{
  font: 15px/21px Arial,Helvetica,Sans-Serif;
  resize: none;
  padding: 5px;
  height: 117px;
  border: 1px solid #59280b;
  background-color: #fff;
}
.wh-form label
{
  display: block;
  padding-bottom: 3px;
  font-weight: bold;
}
.wh-form label + label:not(.whwp-option)
{
  display:inline-block;
}
.wh-form label + label + label:not(.whwp-option)
{
  margin-left:10px;
}
.wh-form input[readonly]
, .wh-form textarea[readonly]
, .wh-form select[readonly]
{
  background: #eeeeee;
}

.whwp-title
{
  font: normal 24px/26px 'AutoPro-Bold', Arial;
  margin-bottom: 5px;
}

.wh-form label.whwp-option
{
  /**/
}
.wh-form input[type='file']
{
  height: 33px;
  border: 0 none;
  font-size: 15px;
  padding: 6px 0;
  line-height: 19px;
  min-width: 157px;
  cursor: pointer;
  text-align: left;
}
.wh-form input[type='submit']
{
  height: 33px;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 6px 10px;
  line-height: 19px;
  min-width: 157px;
  cursor: pointer;
  text-align: left;
}
  .wh-form .invalid > input
, .wh-form .invalid > textarea
, .wh-form .invalid .wh-pulldown
{
  border-color: #c60a17;
}
  .wh-form .invalid > label + label
{
  color: #c60a17;
}

.whwp-survey .whwp-errors
{
  color: #c60a17;
  font-size: 13px;
  padding: 2px 0 8px;
}
.whwp-survey
{
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 15px;
  margin: 30px 0;
}
.whwp-survey .wh-form .fieldgroup
{
  margin-bottom: 15px;
  margin-right: 22%;
}
  .whwp-survey .wh-form input[type='text']
, .whwp-survey .wh-form input[type='email']
, .whwp-survey .wh-form .wh-pulldown
, .whwp-survey .wh-form textarea
{
  width: 100%;
}
  .whwp-survey .wh-form .timegroup input[type='text']
, .whwp-survey .wh-form .dategroup input[type='text']
{
  width: 46px;
}
.whwp-survey .wh-form .dategroup input.year
{
  width: 72px;
}
.whwp-survey .group + .group
{
  border-top: 1px solid #2566a4;
  padding-top: 10px;
}
/* 22 + 43*/
.whwp-survey .wh-form input[size="1"]
{
  width: 31px;
}
.whwp-survey .wh-form input[size="2"]
{
  width: 46px;
}
.whwp-survey .wh-form input[size="3"]
{
  width: 59px;
}
.whwp-survey .wh-form input[size="4"]
{
  width: 72px;
}
.whwp-survey .wh-form input[size="5"]
{
  width: 84px;
}
.whwp-survey .wh-form input[size="6"]
{
  width: 96px;
}
.whwp-survey .wh-form input[size="7"]
{
  width: 109px;
}
.whwp-survey .wh-form input[size="8"]
{
  width: 121px;
}
.whwp-survey .wh-form input[size="9"]
{
  width: 134px;
}
.whwp-survey .wh-form input[size="10"]
{
  width: 146px;
}

