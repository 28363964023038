import '@mod-wrd/js/auth';
import 'dompack/browserfix/reset.css';
import './web/fonts/autopro/autopro.css';
import './web/fonts/fontawesome/fontawesome.css';
import './js/medlon-core.es';
import './pages';
import './css/prikposten.scss'; //must come AFTER medlon-core.es!
import "./shared/suggest";
import "./js/forms";
import * as dompack from 'dompack';
import * as consenthandler from '@mod-publisher/js/analytics/consenthandler';
import * as cookie from "dompack/extra/cookie";
import * as service from "./medlon.rpc.json";
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-publisher/js/analytics/gtm';
import './widgets/button';
import './widgets/cookiesettings';
import './widgets/imageslist';
import './widgets/itemslist';
import './widgets/quicklinks';
import './widgets/thuisprikroutes';
import './widgets/twocolumns';

document.addEventListener("wh:wrdauth-loginfailed", function(event)
{
  event.preventDefault();
  alert("De opgegeven logingegevens zijn niet correct");
});

let showcookiemsg = false;

class initConsentAndMessages
{
  constructor()
  {
    consenthandler.onConsent("analytics", this.loadAnalytics.bind(this) );

    if( document.querySelector(".embeddedobject.cookiesettings") )
      showcookiemsg = false;//Don't show cookie popup if on cookie settings page

    let lastcheck = cookie.read("medlon_lastcheck");//check interval after closing messagebox
    lastcheck = lastcheck ? 1*lastcheck : 0;
    let curtime = new Date().getTime();
    if(curtime - lastcheck < 1000*60*5 && !showcookiemsg) //5 min interval
      return;

    this.showMessages();
  }

  loadGA( ga_account )
  { //Add to head
    let scriptnode = document.createElement("script");
    scriptnode.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + ga_account);
    scriptnode.setAttribute("async", "true");
    document.querySelector("head").appendChild(scriptnode);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag("js", new Date());
    gtag("config", ga_account, { 'anonymize_ip': true });
  }

  // loadHotJar(hotjar_id)
  // {
  //   (function(f,b){
  //       var c;
  //       f.hj=f.hj||function(){(f.hj.q=f.hj.q||[]).push(arguments)};
  //       f._hjSettings={hjid:[hotjar_id], hjsv:4};
  //       c=b.createElement("script");c.async=1;
  //       c.src="//static.hotjar.com/c/hotjar-"+f._hjSettings.hjid+".js?sv="+f._hjSettings.hjsv;
  //       b.getElementsByTagName("head")[0].appendChild(c);
  //   })(window,document);
  // }

  // loadLinkedInInsight(linkedin_partner_id)
  // {
  //    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  //    window._linkedin_data_partner_ids.push(linkedin_partner_id);

  //    (function(){
  //      var s = document.getElementsByTagName("script")[0];
  //      var b = document.createElement("script");
  //      b.type = "text/javascript";
  //      b.async = true;
  //      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
  //      s.parentNode.insertBefore(b, s);
  //    })();
  // }

  loadAnalytics()
  {
    if( whintegration.config.site.googleanalytics )
      this.loadGA( whintegration.config.site.googleanalytics );

    // if( whintegration.config.site.hotjar_id && !document.documentElement.classList.contains("safemode") )
    //   this.loadHotJar(whintegration.config.site.hotjar_id);

    // if( whintegration.config.site.linkedin_partner_id )
    //   this.loadLinkedInInsight(whintegration.config.site.linkedin_partner_id );
  }

  async showMessages()
  {
    let duration = 700;//ms (animation duration)

    let result = await service.GetMessages(showcookiemsg);

    if(result.messages)
    {
      if(result.messages.length == 0)
      {
        var curtime = new Date().getTime();
        cookie.write("medlon_lastcheck", curtime, {duration:365});
      }
      else
      {
        cookie.write("medlon_lastcheck", 0, {duration:365});//reset check interval

        function getDescr(descr)
        {
          let el = <div class="description" />;
          el.innerHTML = descr;
          return el;
        }

        let curpage = document.location.href;
        let settingslink = "";
        if( whintegration.config.site.cookiesettingslink )
          settingslink = whintegration.config.site.cookiesettingslink + '?redirect=' + encodeURIComponent(curpage);

        this.holder= <div id="globalmessage_holder">
                      <div id="globalmessage_inner">
                        { showcookiemsg ? null : <span id="globalmessage_close">Sluiten</span> }
                        {result.messages.map(item =>
                          <div class={"globalmessage" + (item.type ? " globalmessage--" + item.type : "")}>
                            <strong class="title">{item.title}</strong>
                            { getDescr(item.description) }
                            {
                              item.type == "cookiemessage" ?
                                <div class="buttons">
                                  <span class="button" data-action="accept">Cookies accepteren</span>
                                  { settingslink ?
                                      <a class="button" href={settingslink}>Instellingen aanpassen</a>
                                    : null
                                  }
                                </div>
                              : null
                            }
                          </div>
                        )}
                      </div>
                    </div>;//

        let consentbtn = this.holder.querySelector(".globalmessage--cookiemessage .button[data-action='accept']");
        if( consentbtn )
        {
          consentbtn.addEventListener("click", ev => {
            consenthandler.setConsent(["analytics","thirdparty"]);
            this.closeMessagesOverlay();
            document.location.reload();
          });
        }

        this.holder.style.transition = "height " + duration + "ms";
        document.body.appendChild(this.holder);
        this.holder.clientHeight;

        let innerheight = 0;
        for( let node of this.holder.querySelectorAll(".globalmessage") )
          innerheight += node.clientHeight;

        if(innerheight < 150) innerheight = 150;//min-height

        let maxheight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if(innerheight > maxheight)
        {
          innerheight = maxheight;
          this.holder.classList.add('scrollable');
        }
        else
        {
          this.holder.classList.remove('scrollable');
        }

        this.holder.clientHeight;//force css update
        this.holder.style.height = innerheight + "px";

        setTimeout(function(){this.holder.style.height = "auto";}.bind(this),duration);

        this.uniqueid = '';
        for(let c = 0; c < result.messages.length; ++c)
        {
          if( result.messages[c].md5 )
            this.uniqueid += (this.uniqueid ? ',' : '') + result.messages[c].md5;
        }

        let closebtn = this.holder.querySelector("#globalmessage_close");
        if( closebtn )
          closebtn.addEventListener('click', ev => this.closeMessagesOverlay() );
      }
    }
  }

  closeMessagesOverlay()
  {
    var closedmsgs = cookie.read("medlon_msg");
    if(!closedmsgs)
      closedmsgs = this.uniqueid;
    else
      closedmsgs = this.uniqueid + ',' + closedmsgs;

    if( !showcookiemsg )
    {
      let curtime = new Date().getTime();
      cookie.write("medlon_lastcheck", curtime, {duration:365});
      cookie.write("medlon_msg", closedmsgs, {duration:365});
    }

    this.holder.style.height = this.holder.clientHeight + "px";//set so css transition can be used
    this.holder.clientHeight;//force css update
    this.holder.style.height = "0";
  }

  onRPCError(result)
  {
    console.error(result);
  }
}


dompack.onDomReady(() =>
{
  if( !consenthandler.hasConsent("thirdparty") )
  {
    //whintegration.config.site.cookiesettingslink
    let curpage = document.location.href;
    let settingslink = "";
    if( whintegration.config.site.cookiesettingslink )
      settingslink = whintegration.config.site.cookiesettingslink + '?redirect=' + encodeURIComponent(curpage);

    let videonodes = document.querySelectorAll(".video[data-video-options]");
    if( videonodes.length )
    {
      let overlay = <div class="needconsentoverlay">
                      <span class="fa fa-exclamation-circle" /><br />
                      De video kan alleen afgespeeld worden als je <a href={settingslink}>cookies van derden accepteert</a>.
                    </div>;//
      for( let node of videonodes )
        node.appendChild(overlay);
    }

    let mapnodes = document.querySelectorAll(".imageheader--map");
    if( mapnodes.length )
    {
      let overlay = <div class="needconsentoverlay">
                      <span class="fa fa-exclamation-circle" /><br />
                      De Google Maps kaart kan alleen gebruikt<br />
                      worden als je <a href={settingslink}>cookies van derden accepteert</a>.
                    </div>;//
      for( let node of mapnodes )
        node.appendChild(overlay);
    }

  }

});

if( whintegration.config.site.doconsentcheck )
{
  consenthandler.setup("medlon-consent", () => showcookiemsg = true);
  dompack.onDomReady(() =>  new initConsentAndMessages());
}
