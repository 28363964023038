import "./thuisprikroutes.scss";
import * as dompack from 'dompack';
import * as dialogapi from 'dompack/api/dialog';

class thuisprikroutes
{
  constructor(node)
  {
    this.node = node;

    let url = new URL(location.href);
    let postcode = url.searchParams.get("postcode");

    if( postcode )
      this.node.postcode.value = postcode;

    this.node.addEventListener("submit", ev => this.findRoute(ev));
  }

  findRoute(ev)
  {
    if( ev )
      ev.preventDefault();

    let zip = this.node.postcode.value;
    zip = zip.substr(0,4).replace(/([^0-9])/g,'');
    if( zip.length != 4 )
      return;

    this.getRouteForZip( parseInt(zip) );
  }

  async getRouteForZip(zip)
  {
    if( !this.routes )
    {
      let res = await fetch("/.medlon/data/thuisprikroutes.json", { cache: 'reload' });
      if( res && res.status == 200 )
        this.routes = await res.json();
    }

    let details = this.routes.find(_ => _.postcode == zip );

    let message = "";
    if( details && details.days )
      message = "We komen op " + this.formatWeekDays(details.days) + " bij u in de buurt voor het prikken aan huis. Dit doen wij op indicatie van uw behandelend arts.";
    else
      message = "Er is geen thuisprik route bij u in de buurt gevonden.";

    this.showDialog(message);
  }

  async showDialog(message)
  {
    let dialog = dialogapi.createDialog();

    let closebtn = <span role="button" aria-label="Close dialog" tabindex="0" class="medlon-dialog__close fa fa-times" />;
    dialog.contentnode.classList.add("medlon-dialog--thuisprikroutes");
    dialog.contentnode.parentNode.appendChild(closebtn);
    closebtn.addEventListener("click", ev => dialog.resolve() );

    dialog.contentnode.appendChild(<div class="medlon-dialog__content">
                                     <h2>Thuisprikroutes</h2>
                                     <p class="normal">{message}</p>
                                   </div>);

    await dialog.runModal();
  }

  formatWeekDays(days)
  {
    if( days == "ma/di/wo/do/vr" )
      return "doordeweekse dag";

    if( days == "ma/di/wo/do/vr/za/zo" )
      return "elke dag";

    let daynames = { "ma" : "maandag"
                   , "di" : "dinsdag"
                   , "wo" : "woensdag"
                   , "do" : "donderdag"
                   , "vr" : "vrijdag"
                   , "za" : "zaterdag"
                   , "zo" : "zondag"
                   };

    let parts = days.split("/");

    if( parts.length == 1 )
      return daynames[parts[0]];

    let formattedstr = "";
    let numdays = parts.length;

    parts.forEach((dayshort,i) => {
      if( i == numdays - 1 )
        formattedstr += " & ";
      else if( i )
        formattedstr += ", ";

      formattedstr += daynames[dayshort];
    });

    return formattedstr;
  }
}

dompack.register(".embeddedobject-thuisprikroutes form", node => new thuisprikroutes(node));
