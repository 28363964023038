require('designfiles/wh.form')
require('designfiles/wh.form.model.datefields')
;
/*! LOAD: wh.form, wh.form.model.datefields
!*/

(function($) { //mootools/scope wrapper
"use strict";

function forms_init()
{
  if(!document.querySelector("div.accountpage"))
    return;

  $$("form.wh-form").each(function(form)
  {
    var handler = $wh.Form.setupHandler(form);
  });
}

window.addEvent("domready", forms_init);

})(document.id); //end mootools/scope wrapper
